import request from '@/utils/request'


  export function get(params) {
    return request({
      url: '/api/InternalSharing',
      params: params,
      method: 'get',
      header: {
        'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
      },
  
    })
  }
  
  export function add (params) {
    return request({
      url: '/api/InternalSharing',
      data: params,
      method: 'post',
      header:{
        'Content-Type':'application/json;charset=utf-8'  //如果写成contentType会报错
      },
      
    })
  }
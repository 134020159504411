<template>
  <div>
    <van-sticky>
      <van-dropdown-menu>
        <van-dropdown-item
          v-model="page.sorterType"
          :options="sorterTypeOptions"
          @change="onSearch"
        />
        <van-dropdown-item
          v-model="page.filterData.readStatus"
          :options="readStatusOptions"
          @change="onSearch"
          v-if="listType != 0"
        />
        <van-dropdown-item title="筛选" ref="filter">
          <van-cell center>
            <template #right-icon>
              <van-field
                readonly
                clickable
                name="calendar"
                :value="page.filterData.timeRange"
                label="选择日期范围"
                placeholder="点击选择"
                @click="showCalendar = true"
              />
              <van-calendar
                v-model="showCalendar"
                type="range"
                @confirm="onSelectedTimeRange"
                :min-date="minDate"
              />
            </template>
          </van-cell>
          <van-cell center title="部门" v-if="listType != 0">
            <template #right-icon>
              <van-search
                v-model="page.filterData.departmentName"
                placeholder="请输入准确的部门名称"
                @change="searchOnchange"
              />
            </template>
          </van-cell>
          <van-cell center title="姓名" v-if="listType != 0">
            <template #right-icon>
              <van-search
                v-model="page.filterData.userName"
                placeholder="请输入姓名"
                @change="searchOnchange"
              />
            </template>
          </van-cell>
          <van-cell center title="模板名称">
            <template #right-icon>
              <van-search
                v-model="page.filterData.templateTitle"
                placeholder="请输入(示例：日报、周报、月报)"
                @change="searchOnchange"
              />
            </template>
          </van-cell>
          <van-cell center title="选择分享状态" v-if="listType != 2">
            <template>
              <van-dropdown-menu>
                <van-dropdown-item
                  v-model="page.filterData.shareStatus"
                  :options="shareStatusOptions"
                  @change="searchOnchange"
                />
              </van-dropdown-menu>
            </template>
          </van-cell>
          <div style="padding: 5px 16px">
            <van-button round style="width: 45%" @click="onReset">
              重置
            </van-button>
            <van-button
              type="danger"
              round
              :loading="upLoading"
              style="width: 45%; margin-left: 9%"
              @click="onSearch"
            >
              确定
              <van-badge
                :content="total || total == 0 ? total + '条' : ''"
                max="999"
              />
            </van-button>
          </div>
        </van-dropdown-item>
      </van-dropdown-menu>
    </van-sticky>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="upLoading"
        :finished="finished"
        @load="onLoad"
        finished-text="没有更多了"
      >
        <van-cell-group v-for="item in worklogs" :key="item.id">
          <van-cell @click="toDetail(item)" :border="false">
            <van-col span="3">
              <van-image
                style="float: left"
                round
                width="41px"
                height="41px"
                fit="fill"
                :src="item.headImgurl"
              />
            </van-col>

            <van-col span="21">
              <div style="width: 100%; display: flex; align-items: center">
                <a
                  style="
                    color: #069;
                    font-size: 16px;
                    font-weight: bold;
                    margin-right: 5px;
                  "
                  @click.stop="searchByType({ trueName: item.trueName })"
                >
                  {{ item.trueName }}
                </a>

                <van-tag
                  mark
                  plain
                  color="#9d1d22"
                  @click.stop="searchByType({ departName: item.departName })"
                  >{{ item.departName }}
                </van-tag>
                <userIcon :iconstring="item.icon"></userIcon>

                <van-tag
                  style="margin-left: 5px; margin-right: 5px"
                  plain
                  type="primary"
                  v-if="item.templateTitle"
                  @click.stop="
                    searchByType({ templateTitle: item.templateTitle })
                  "
                  >{{ item.templateTitle }}</van-tag
                >
                <img
                  v-if="item.shareToBbsId"
                  :src="bbsIcon"
                  class="van-icon__image"
                  @click.stop="tobbs(item)"
                />
                <van-tag type="danger" style="float: right" v-if="item.isTop"
                  >置顶</van-tag
                >
              </div>
              <div style="color: #696969">
                <span
                  @click.stop="
                    searchByType({ creationTime: item.creationTime })
                  "
                >
                  {{ item.creationTime | dateFormat("YYYY/MM/DD HH:mm") }}
                </span>
                <span style="margin-left: 5px">
                  浏览{{ item.readCount }}人次
                </span>
                <van-icon
                  @click.stop="toTop(item)"
                  name="back-top"
                  size="14px"
                  style="float: right"
                  v-if="canTop(item)"
                  >置顶</van-icon
                >
              </div>
            </van-col>

            <van-col span="2"> </van-col>
            <van-col span="22" style="position: relative">
              <div class="acontent van-multi-ellipsis--200">
                <div class="bbscontent" v-html="item.detailsHtml"></div>
                <div
                  class="showqw"
                  style="
                    color: rgb(0, 102, 153);
                    font-size: 16px;
                    font-weight: bold;
                    background: white;
                  "
                >
                  点击查看全文
                </div>
                <div style="bottom: 0; position: absolute; right: 0">
                  <div>
                    <span
                      style="float: right"
                      v-if="
                        listType != 0 && $store.state.user.id != item.creatorId
                      "
                    >
                      <van-tag
                        round
                        plain
                        color="#9d1d22"
                        v-if="!item.readStatus"
                        @click.stop="searchByType({ readStatus: 2 })"
                        >未读</van-tag
                      >
                      <van-tag
                        round
                        plain
                        color="#D4D4D4"
                        v-if="item.readStatus"
                        @click.stop="searchByType({ readStatus: 1 })"
                        >已读</van-tag
                      >
                    </span>
                  </div>
                  <div @click.stop="toShare(item)" style="clear: both">
                    <van-icon name="share-o" v-if="canShare(item)" />
                    <span>{{
                      item.isShared
                        ? getShareToHRDepartment(item)
                        : canShare(item)
                        ? "分享"
                        : "未分享"
                    }}</span>
                  </div>
                </div>
              </div>
            </van-col>
          </van-cell>
        </van-cell-group>
      </van-list>
    </van-pull-refresh>
    <div
      class="fixed-toolbar"
      style="right: 10px; top: 144px"
      v-if="
        listType == 0 &&
        listType == 1 &&
        $store.state.user.mangedHRDepartmentIds &&
        $store.state.user.mangedHRDepartmentIds.length > 0
      "
      @click.stop="toexport"
    >
      <span style="color: #1989fa; font-size: 10px">导出￬</span>
    </div>
  </div>
</template>

<style scoped>
.fixed-toolbar {
  position: fixed;
  background-color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #409eff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  box-shadow: 0 0 6px rgb(0 0 0 / 12%);
  cursor: pointer;
  z-index: 5;
}
</style>

<script>
import { mapGetters } from "vuex";
import { getPageList, exportExcel } from "@/api/worklog";
import { addTop } from "@/api/top";
import UserIcon from "../../components/userIcon";
import { Toast, Dialog } from "vant";

export default {
  name: "worklogList",
  props: {
    listType: String,
    topPermissionCount: Number,
  },
  emits: ["onShare", "updateTotal"],
  data() {
    return {
      bbsIcon:
        "http://kmfile.bjznpz.com/wwwroot/zn_files/210326/fe7adea1aeff434b9dc6e804b42caa86.png",
      allHRDepartment: [],
      allMangedHRDepartments: [],
      refreshing: false,
      finished: false,
      upLoading: false,
      worklogs: [],
      total: "",
      page: {
        current: 1,
        pageSize: 10,
        sorterType: 1,
        filterData: {
          listType: this.listType,
          timeRange: "",
          departmentName: "",
          userName: "",
          readStatus: 0,
          shareStatus: 0,
          templateTitle: "",
          onlayGetTotal: false,
        },
      },
      sorterTypeOptions: [
        { text: "最新", value: 1 },
        { text: "最早", value: 2 },
        { text: "最热", value: 3 },
      ],
      readStatusOptions: [
        { text: "全部状态", value: 0 },
        { text: "已读", value: 1 },
        { text: "未读", value: 2 },
      ],
      shareStatusOptions: [
        { text: "全部", value: 0 },
        { text: "已分享", value: 1 },
        { text: "未分享", value: 2 },
      ],

      showCalendar: false,
      minDate: new Date(2022, 3, 7),
    };
  },
  components: {
    UserIcon,
  },
  computed: {},
  created() {
    this.getAllDepartments().then((d) => {
      this.allHRDepartment = d;
      let mangedHRDepartments = this.allHRDepartment.filter(
        (x) => this.$store.state.user.mangedHRDepartmentIds.indexOf(x.id) != -1
      );
      this.allMangedHRDepartments = [];
      mangedHRDepartments.forEach((x) => {
        this.allMangedHRDepartments = this.allMangedHRDepartments.concat(
          this.allHRDepartment.filter((v) => v.path.startsWith(x.path))
        );
      });
    });
  },
  updated() {
    this.showQW();
  },

  methods: {
    ...mapGetters(["getAllDepartments"]),
    getShareToHRDepartment(item) {
      var shareHRDepartment = this.allHRDepartment.filter(
        (x) => "," + x.id + "," == item.originalShareToHRDepartmentIds
      )[0];
      if (!shareHRDepartment) return "已分享";
      else if (shareHRDepartment.levelIndex == 0) return "已分享-全公司";
      else return `已分享-${shareHRDepartment.name.replace("经管委员会", "")}`;
    },
    canShare(item) {
      if (item.creatorId == this.$store.state.user.id) return true;
      return this.hasPower(item);
    },
    toShare(item) {
      if (!this.canShare(item)) {
        this.searchByType({ shareStatus: item.isShared ? 1 : 2 });
        return;
      }
      if (this.$listeners.onShare) {
        this.$emit("onShare", item, this.hasPower(item));
      }
    },
    hasPower(item) {
      if (
        !this.$store.state.user.mangedHRDepartmentIds ||
        this.$store.state.user.mangedHRDepartmentIds.length <= 0
      )
        return false;

      if (
        this.allMangedHRDepartments
          .map((x) => x.id)
          .indexOf(item.hrDepartmentId) == -1
      )
        return false;
      return true;
    },
    canTop(item) {
      if (this.listType == 0) return false;
      if (item.isTop) return false;

      if (item.readStatus && this.topPermissionCount > 0) return true;
      if (
        this.allMangedHRDepartments.filter((x) => x.levelIndex == 0).length > 0
      )
        return true;
      if (
        this.listType == 1 &&
        this.allMangedHRDepartments.filter((x) => x.id == item.hrDepartmentId)
          .length > 0
      )
        return true;
      return false;
    },
    toTop(item) {
      if (!this.canTop(item)) return false;

      Dialog.confirm({
        message: "【置顶效果】自操作时间起24小时后自动失效，确定现在置顶？",
      }).then(() => {
        addTop({
          itemId: item.id,
          itemType: 12,
          typeId: this.listType,
        }).then((d) => {
          if (d.data.success) {
            var count = this.topPermissionCount - 1;
            this.$emit("update:topPermissionCount", count);
            item.isTop = true;
            Toast("置顶成功");
          } else {
            Toast(
              "置顶失败" +
                (d.data.errorMessage ? `原因（${d.data.errorMessage}）` : "")
            );
          }
        });
      });
    },
    async onReset() {
      this.page.filterData.timeRange = "";
      this.page.filterData.departmentName = "";
      this.page.filterData.userName = "";
      this.page.filterData.shareStatus = 0;
      this.page.filterData.templateTitle = "";
      await this.searchOnchange();
    },
    async searchOnchange() {
      this.upLoading = true;
      this.page.filterData.onlayGetTotal = true;
      let d = await getPageList({ ...this.page }); //({ ...this.page })
      if (!d.data.success && d.data.errorMessage) {
        Toast(d.data.errorMessage);
        return;
      }

      this.total = d.data.total;
      this.$emit("updateTotal", this.total);
      this.page.filterData.onlayGetTotal = false;
      this.upLoading = false;
    },
    async searchByType(item) {
      if (item.trueName) {
        if (this.listType == 0) return;
        this.page.filterData.userName = item.trueName;
      }
      if (item.departName) {
        if (this.listType == 0) return;
        this.page.filterData.departmentName = item.departName;
      }
      if (item.readStatus) {
        if (
          this.listType == 0 ||
          this.page.filterData.readStatus == item.readStatus
        )
          return;
        this.page.filterData.readStatus = item.readStatus;
        this.onSearch();
        return;
      }
      if (item.shareStatus) {
        if (this.listType == 2) return;
        this.page.filterData.shareStatus = item.shareStatus;
      }
      if (item.templateTitle) {
        this.page.filterData.templateTitle = item.templateTitle;
      }
      if (item.creationTime) {
        var date = new Date(item.creationTime);
        date.setDate(date.getDate() + 1);
        this.page.filterData.timeRange = `${this.dayjs(
          item.creationTime
        ).format("YYYY/MM/DD")} - ${this.dayjs(date).format("YYYY/MM/DD")}`;
      }
      this.$refs.filter.toggle(true);
      await this.searchOnchange();
    },

    async onSelectedTimeRange(date) {
      const [start, end] = date;
      this.showCalendar = false;
      this.page.filterData.timeRange = `${this.dayjs(start).format(
        "YYYY/MM/DD"
      )} - ${this.dayjs(end).format("YYYY/MM/DD")}`;
      this.$refs.filter.toggle(true);
      await this.searchOnchange();
    },
    onRefresh() {
      this.onSearch();
      this.refreshing = false;
    },
    onSearch() {
      this.$refs.filter.toggle(false);
      this.worklogs = [];
      this.finished = false;
      this.page.current = 1;
      this.page.pageSize = 10;

      this.onLoad();
    },
    toDetail(d) {
      d.readStatus = true;
      try {
        this.$router.push({
          path: "/worklog/detail",
          query: { id: d.id, templateTitle: d.templateTitle },
        });
      } catch (error) {
        console.log(error);
      }
    },
    async onLoad() {
      this.upLoading = true;
      let d = await getPageList({ ...this.page }); //({ ...this.page })
      if (!d.data.success && d.data.errorMessage) {
        Toast(d.data.errorMessage);
        return;
      }

      d.data.data.forEach(
        (item) => (item.shareToBbsId = item.shareToBbsId || "")
      );

      this.worklogs.push(...d.data.data);
      this.total = d.data.total;
      this.$emit("updateTotal", this.total);

      this.upLoading = false;
      if (this.page.current * this.page.pageSize < d.data.total) {
        this.page.current++;
      } else {
        this.finished = true;
      }
    },
    showQW() {
      var listdiv = document.getElementsByClassName("bbscontent");
      listdiv.forEach((element) => {
        if (
          element.clientHeight > 50 ||
          element.nextSibling.style.display == "block"
        ) {
          element.nextSibling.style.display = "block";
        } else {
          element.nextSibling.style.display = "none";
        }
      });
    },

    tobbs(item) {
      this.$router.push({
        path: "/bbs/detail",
        query: { para: item.shareToBbsId, type: 0, back: true },
      });
    },

    toexport() {
      if (
        this.listType == 0 ||
        (this.listType == 1 &&
          this.$store.state.user.mangedHRDepartmentIds &&
          this.$store.state.user.mangedHRDepartmentIds.length > 0)
      ) {
        Dialog.confirm({
          title: "已筛选好了工作总结，现在导出到excel？",
        }).then(async () => {
          this.upLoading = true;
          this.page.filterData.onlayGetTotal = false;
          let res = await exportExcel({ ...this.page }); //({ ...this.page })
          const file = new Blob([res.data], {
            type: "application/vnd.ms-excel",
          });
          const url = URL.createObjectURL(file);
          const a = document.createElement("a");
          a.style.display = "none";
          a.download = `工作总结${
            this.listType == 0 ? "（我的）" : "（我的部门）"
          }.xlsx`;
          a.href = url;
          a.click();
          this.upLoading = false;
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.bbscontent {
  min-height: 40px;
  max-height: 70px;
  max-width: 88%;
  overflow: hidden;
}

.acontent {
  overflow: hidden;
  text-overflow: ellipsis;

  /deep/ ol {
    list-style: decimal !important;
    margin-left: 25px;
  }
  /deep/ ol li {
    list-style-position: outside;
  }
  /deep/ img {
    max-width: 33% !important;
    display: block;
    border: 0;
  }
  /deep/ video {
    max-width: 100% !important;
  }
}

.van-search {
  padding: 0;
}
.van-cell__value .van-dropdown-menu .van-dropdown-menu__bar {
  height: 30px;
}
::v-deep .van-cell__value .van-dropdown-menu .van-dropdown-menu__bar {
  height: 30px;
}
</style>
<template>
  <div class="container" ref="wrapper">
    <van-sticky>
      <van-nav-bar
        title="工作总结"
        left-arrow
        @click-left="$router.replace({ path: '/' })"
      >
        <template #right>
          <div>
            <van-icon
              name="chart-trending-o"
              @click="tochart"
              color="#1989fa"
              size="10"
              v-if="hasDashboardPermission"
              ><span style="position: relative; top: -2px">统计</span></van-icon
            >
            <van-icon
              name="question"
              @click="tohelp()"
              color="#1989fa"
              size="10"
              style="margin-left: 3px"
              ><span style="position: relative; top: -2px">文档</span></van-icon
            >
            <van-icon
              name="plus"
              size="12"
              style="margin-left: 3px"
              @click="toadd()"
              ><span style="position: relative; top: -2px"
                >写总结</span
              ></van-icon
            >
          </div>
        </template>
      </van-nav-bar>
    </van-sticky>

    <van-notice-bar
      scrollable
      :text="myNotice"
      v-if="myNotice && myNotice.trim().length > 0"
      ref="noticeBar"
    />
    <van-row>
      <van-tabs
        v-model="listType"
        :ellipsis="false"
        sticky
        @change="tabOnchange"
      >
        <van-tab>
          <template #title>
            <span class="child">我的总结</span>
          </template>
          <WorkLogList
            listType="0"
            @onShare="onShare"
            @updateTotal="updateTotal"
          ></WorkLogList>
        </van-tab>
        <van-tab>
          <template #title>
            <span class="child">我的部门</span>
          </template>
          <WorkLogList
            ref="worklogList1"
            listType="1"
            @onShare="onShare"
            @updateTotal="updateTotal"
            :topPermissionCount.sync="topPermissionCount_Department"
          ></WorkLogList>
        </van-tab>
        <van-tab>
          <template #title>
            <span class="child">其他分享</span>
          </template>
          <WorkLogList
            listType="2"
            @onShare="onShare"
            @updateTotal="updateTotal"
            :topPermissionCount.sync="topPermissionCount"
          ></WorkLogList>
        </van-tab>
        <van-tab
          v-if="
            this.$store.state.user.mangedHRDepartmentIds &&
            this.$store.state.user.mangedHRDepartmentIds.length > 0
          "
        >
          <!-- <van-tab> -->
          <template #title>
            <span class="child">统计</span>
          </template>
          <van-field
            readonly
            clickable
            name="calendar"
            :value="timeRange"
            label="选择日期范围"
            placeholder="点击选择"
            @click="showCalendar = true"
          />
          <van-calendar
            v-model="showCalendar"
            type="range"
            @confirm="onSelectedTimeRange"
            :min-date="minDate"
          />
          <ve-table
            id="tb"
            :columns="columns"
            :table-data="tableData"
            :expand-option="expandOption"
            row-key-field-name="id"
            max-height="calc(100vh - 192px)"
          />
        </van-tab>
      </van-tabs>
    </van-row>
    <van-action-sheet
      v-model="shareInfo.isShow"
      title="选择分享到公司或中心级部门"
    >
      <div style="padding: 10px">
        <van-row style="margin: 10px 0px 10px 0px">
          <van-col span="8"
            ><van-checkbox
              v-model="shareInfo.isSelectCompany"
              @click="onSelectDepartment(0)"
              >全公司</van-checkbox
            ></van-col
          >
          <van-col span="16">
            <van-checkbox
              style="margin-left: 20px"
              v-model="shareInfo.isSelectCenter"
              v-if="userCenterDepartment"
              @click="onSelectDepartment(1)"
              >{{
                userCenterDepartment.name.replace("经管委员会", "")
              }}</van-checkbox
            ></van-col
          >
        </van-row>
        <van-row
          style="margin: 10px 0px 10px 0px"
          v-if="shareInfo.hasShareBbsPermission"
        >
          <van-col span="8">
            <van-checkbox
              v-model="shareInfo.isSelectBbs"
              :disabled="Boolean(shareInfo.currentShare.shareToBbsId)"
              >中酿心声
              <img :src="bbsIcon" class="van-icon__image" /></van-checkbox
          ></van-col>
          <van-col span="16"></van-col>
        </van-row>
        <van-button type="info" block size="small" @click="submitShare">{{
          !shareInfo.isSelectCenter &&
          !shareInfo.isSelectCompany &&
          shareInfo.currentShare.originalShareToHRDepartmentIds
            ? "取消分享"
            : "确定分享"
        }}</van-button>
      </div>
    </van-action-sheet>
    <van-action-sheet
      v-model="isShowSelectTemplate"
      :actions="templates"
      @select="onSelectTemplate"
      cancel-text="取消"
      close-on-click-action
    />
    <child-vetable v-if="false"></child-vetable>
  </div>
</template>

<script>
import Vue from "vue";
import "vue-easytable/libs/theme-default/index.css";
import { Toast } from "vant";
import { mapGetters } from "vuex";
import { add } from "@/api/internalSharing";
import { shareToBbs } from "@/api/worklog";
import WorkLogList from "./components/workloglist";
import ChildVetable from "./components/childvetable";
import { VeTable, VeLoading } from "vue-easytable";
import {
  getStatisticalData,
  getCurrentUserPartitionNewestWorkLogs,
  getTopPermissionCount,
} from "@/api/worklog";
import { checkUserHasDashboardPermission } from "@/api/keshihua";

Vue.use(VeTable);
Vue.use(VeLoading);

export default {
  data() {
    return {
      bbsIcon:
        "http://kmfile.bjznpz.com/wwwroot/zn_files/210326/fe7adea1aeff434b9dc6e804b42caa86.png",
      scroll: 0,
      listType: 0,
      total0: "",
      total1: "",
      total2: "",
      shareInfo: {
        isShow: false,
        currentShare: {},
        shareType: 12,
        isSelectCompany: true,
        isSelectCenter: false,
        isSelectBbs: false,
        hasShareBbsPermission: false,
      },
      allHRDepartment: [],
      userCenterDepartment: null,
      isShowSelectTemplate: false,
      templates: [],

      topPermissionCount_Department: 0,
      topPermissionCount: 0,
      myNotice: "",

      timeRange: "",
      showCalendar: false,
      minDate: new Date(2022, 3, 7),
      loadingInstance: null,
      expandOption: {
        expandedRowKeys: [],
        render: ({ row }) => {
          return (
            <child-vetable
              row={row}
              timeRange={this.timeRange}
              on-beforeLoadData={this.showLoadingTable}
              on-finishedLoadData={this.hideLoadingTable}
              on-onWriteCountOnlick={this.navToUserTab2}
            />
          );
        },
        afterExpandRowChange: ({ afterExpandedRowKeys }) => {
          this.expandOption.expandedRowKeys = afterExpandedRowKeys;
        },
      },
      columns: [
        {
          field: "",
          key: "icon",
          // 设置需要显示展开图标的列
          type: "expand",
          title: "",
          width: 50,
          align: "center",
        },
        {
          field: "name",
          key: "name",
          title: "部门名称",
          align: "left",
        },
        {
          field: "writeCount",
          key: "writeCount",
          title: "总结数",
          align: "center",
          renderBodyCell: ({ row }) => {
            return (
              <span
                class="text-bold"
                style="color:#1890ff;"
                on-click={() => this.navToTab2(row)}
              >
                {row.writeCount}
              </span>
            );
          },
        },
        {
          field: "readCount",
          key: "readCount",
          title: "浏览数",
          align: "center",
        },
        {
          field: "readUserCount",
          key: "readUserCount",
          title: "浏览人数",
          align: "center",
        },
        {
          field: "sharedCount",
          key: "sharedCount",
          title: "分享数",
          align: "center",
        },
      ],
      tableData: [],
      
      hasDashboardPermission: false,
    };
  },
  components: {
    WorkLogList,
    ChildVetable,
  },
  computed: {},
  created() {
    this.getAllDepartments().then((d) => {
      this.allHRDepartment = d;
    });
    this.getUserWorkLogTemplates().then((d) => {
      this.templates = d.map((x) => ({
        id: x.id,
        name: x.title,
        template: x.detailsHtmlTemplate || "",
      }));
      try {
        this.buildMyNotice();
      } catch (e) {
        console.log(e);
      }
    });

    this.setTopPermissionCount();

    checkUserHasDashboardPermission({ dashboardId: 76 }).then((d) => {
      this.hasDashboardPermission = d && d.data.success && d.data.data;
    });
  },
  //此钩子函数会反复触发,是keep-alive特有的钩子函数，取滚动高度
  activated() {
    this.isShowSelectTemplate = false;
    this.$refs.wrapper.scrollTop = this.scroll || 0;
  },
  //路由离开时的钩子函数,存储滚动高度
  beforeRouteLeave(to, from, next) {
    this.scroll = this.$refs.wrapper.scrollTop || 0;
    next();
  },

  updated() {},
  watch: {},
  mounted() {},
  methods: {
    ...mapGetters(["getAllDepartments", "getUserWorkLogTemplates"]),
    updateTotal(total) {
      this["total" + this.listType] = total;
    },
    onShare(item, hasShareBbsPermission) {
      this.shareInfo.hasShareBbsPermission = hasShareBbsPermission;
      this.setUserCenterDepartment(item.hrDepartmentId);
      this.shareInfo.currentShare = item;
      if (item.originalShareToHRDepartmentIds == ",100,") {
        this.shareInfo.isSelectCompany = true;
        this.shareInfo.isSelectCenter = false;
      } else if (item.originalShareToHRDepartmentIds) {
        this.shareInfo.isSelectCompany = false;
        this.shareInfo.isSelectCenter = true;
      } else {
        this.shareInfo.isSelectCompany = true;
        this.shareInfo.isSelectCenter = false;
      }
      this.shareInfo.isSelectBbs=Boolean(item.shareToBbsId);
      this.shareInfo.isShow = true;
    },
    async setUserCenterDepartment(departmentId) {
      const department = this.allHRDepartment.filter(
        (x) => x.id == departmentId
      )[0];
      this.userCenterDepartment = this.allHRDepartment.filter(
        (x) => department.path.startsWith(x.path) && x.levelIndex == 2
      )[0];
    },
    onSelectDepartment(type) {
      if (type == 0) {
        this.shareInfo.isSelectCenter = false;
      } else {
        this.shareInfo.isSelectCompany = false;
      }
    },
    async submitShare() {
      if (
        !this.shareInfo.isSelectCompany &&
        !this.shareInfo.isSelectCenter &&
        !this.shareInfo.currentShare.originalShareToHRDepartmentIds &&
        (!this.shareInfo.isSelectBbs||this.shareInfo.isSelectBbs&&this.shareInfo.currentShare.shareToBbsId)
      ) {
        return;
      }

      let shareToDepartmentIds = this.shareInfo.isSelectCompany
        ? [100]
        : this.shareInfo.isSelectCenter
        ? [this.userCenterDepartment.id]
        : [];
      const d = await add({
        typeId: this.shareInfo.shareType,
        sharedId: this.shareInfo.currentShare.id,
        departmentIds: shareToDepartmentIds,
      });
      if (d.data.success) {
      let isSharedBbsSuccess = false;
      if (
        !this.shareInfo.currentShare.shareToBbsId &&
        this.shareInfo.isSelectBbs
      ) {
        const shareResult = await shareToBbs({
          id: this.shareInfo.currentShare.id,
        });
        isSharedBbsSuccess = shareResult.data.success;
        this.shareInfo.currentShare.shareToBbsId = shareResult.data.data;
      }

        Toast(
          shareToDepartmentIds.length > 0
            ? "分享成功" + (isSharedBbsSuccess ? "（已同步至心声）" : "")
            : isSharedBbsSuccess
            ? "分享同步至心声成功"
            : "取消分享成功"
        );
        this.shareInfo.currentShare.isShared = shareToDepartmentIds.length > 0;
        this.shareInfo.currentShare.originalShareToHRDepartmentIds =
          shareToDepartmentIds.length > 0 ? `,${shareToDepartmentIds[0]},` : "";
        this.shareInfo.isShow = false;
      } else {
        Toast(d.data.errorMessage);
      }
    },
    tohelp() {
      this.$router.push({
        path: "/bbs/detail?",
        query: {
          para: "bad02f8f-9005-4a3d-b7ae-f77ae6da438d",
          type: 0,
          back: true,
        },
      });
    },
    toadd() {
      if (this.templates.length <= 1) {
        this.$router.push({
          path: "/worklog/add",
          query: {
            templateId:
              this.templates.length == 1 ? this.templates[0].id : null,
          },
        });
      } else {
        this.isShowSelectTemplate = true;
      }
    },

    onSelectTemplate(item) {
      this.isShowSelectTemplate = false;
      this.$router.push({
        path: "/worklog/add",
        query: { templateId: item.id },
      });
    },
    showLoadingTable() {
      if (!this.loadingInstance) {
        this.loadingInstance = VeLoading({
          target: document.querySelector("#tb"),
          // 等同于
          // target:"#loading-container"
          name: "wave",
        });
      }

      this.loadingInstance.show();
    },
    hideLoadingTable() {
      this.loadingInstance.close();
    },
    async onSelectedTimeRange(date) {
      this.showLoadingTable();
      const [start, end] = date;
      this.showCalendar = false;
      this.timeRange = `${this.dayjs(start).format(
        "YYYY/MM/DD"
      )} - ${this.dayjs(end).format("YYYY/MM/DD")}`;
      var d = await getStatisticalData({ timeRange: this.timeRange });
      if (d.data.success) {
        this.tableData = d.data.data;
      } else if (d.data.errorMessage) {
        alert(d.data.errorMessage);
      }
      this.expandOption.expandedRowKeys = [];
      this.hideLoadingTable();
    },
    navToTab2(row) {
      this.listType = 1;
      setTimeout(() => {
        this.$refs.worklogList1.page.filterData.timeRange = this.timeRange;
        this.$refs.worklogList1.searchByType({ departName: row.name });
      }, 200);
    },
    navToUserTab2(row) {
      this.listType = 1;
      setTimeout(() => {
        this.$refs.worklogList1.page.filterData.timeRange = this.timeRange;
        this.$refs.worklogList1.searchByType({ trueName: row.trueName });
      }, 200);
    },
    buildMyNotice() {
      if (this.templates.length <= 0) return;
      getCurrentUserPartitionNewestWorkLogs().then((d) => {
        if (!d.data.success || !d.data.data) return;
        let notices = [];
        let worklogs = d.data.data.map((x) => {
          x.details = x.detailsHtml.replace(/<[^>]*>/g, "");
          return x;
        });
        let templates = this.templates.map((x) => {
          x.matchs = [];
          if (/>([^>]+?：<?)</gi.test(x.template)) {
            let matchs = x.template.match(/>([^>]+?：<?)</gi);
            x.matchs = matchs.map((x) =>
              x
                .replace(">", "")
                .replace("<", "")
                .replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&")
            );
          }
          return x;
        });

        var dayNotice = this.buildNotice(worklogs, templates, "日报", [
          "明日",
          "明天",
        ]);
        if (dayNotice) {
          dayNotice = dayNotice.replace("明日", "今日").replace("明天", "今天");
          notices.push(dayNotice);
        }

        var weekNotice = this.buildNotice(worklogs, templates, "周报", [
          "下周",
        ]);
        if (weekNotice) {
          weekNotice = weekNotice.replace("下周", "本周");
          notices.push(weekNotice);
        }

        var monthNotice = this.buildNotice(worklogs, templates, "月报", [
          "下月",
        ]);
        if (monthNotice) {
          monthNotice = monthNotice.replace("下月", "本月");
          notices.push(monthNotice);
        }

        this.myNotice = notices.join(" | ");
      });
    },
    buildNotice(worklogs, templates, templateTitleKeyword, matchKeywords) {
      let notice = "";
      let log = worklogs.filter(
        (x) =>
          x.templateTitle && x.templateTitle.indexOf(templateTitleKeyword) != -1
      )[0];
      if (log && log.details) {
        let template = templates.filter(
          (x) =>
            x.id == log.templateId &&
            x.matchs.filter(
              (f) => matchKeywords.filter((m) => f.indexOf(m) != -1).length > 0
            ).length > 0
        )[0];
        if (template) {
          let match = template.matchs.filter(
            (f) => matchKeywords.filter((m) => f.indexOf(m) != -1).length > 0
          )[0];

          var reg = new RegExp(match, "ig");
          if (!reg.test(log.details)) return notice;

          let matchIdx = template.matchs.indexOf(match);
          if (matchIdx == template.matchs.length - 1) {
            notice = log.details.substring(log.details.indexOf(match)).trim();
          } else {
            let startIdx = log.details.indexOf(match);
            let endIdx =
              startIdx +
              match.length +
              log.details
                .substring(log.details.indexOf(match))
                .replace(match, "")
                .indexOf(template.matchs[matchIdx + 1]);
            notice = log.details.substring(startIdx, endIdx).trim();
          }
          if (!notice.startsWith(match)) return notice;
          if (notice.replace(match, "").trim().length <= 0) {
            notice = "";
          }
        }
      }
      return notice;
    },
    tabOnchange(index) {
      if (index == 3 && this.tableData.length <= 0) {
        setTimeout(() => {
          var date = new Date();
          date.setDate(date.getDate() + -1);
          this.onSelectedTimeRange([date, new Date()]);
        }, 300);
      }
    },
    setTopPermissionCount() {
      getTopPermissionCount().then((d) => {
        if (d.data.success) {
          this.topPermissionCount_Department = d.data.data[1];
          this.topPermissionCount = d.data.data[2];
        }
      });
    },
    tochart() {
      //hideMenu=true隐藏grafana菜单栏，hideHeader=true隐藏grafana头部，hideSubMenuControlsLink=true隐藏查看其他报表按钮
      let dashboardPath='/grafana/d/tuK8jp87k/gong-zuo-zong-jie-tong-ji?orgId=1&theme=light';
      let url =
      dashboardPath +
        "&hideMenu=true&hideHeader=true&hideSubMenuControlsLink=true";
      // kiosk不传是默认、kiosk=tv、kiosk
      //url += "&kiosk";
      this.$router.push({
        path: "/frame?",
        query: {
          title: "工作总结统计",
          url: url,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}
</style>
<template>
  <div class="child-vetable">
    <ve-table id="tb" :columns="columns" :table-data="tableData" />
  </div>
</template>
<script>
import { getUserStatisticalData } from "@/api/worklog";
export default {
  name: "childVetable",
  props: {
    row: Object,
    timeRange: String,
  },
  emits: ["beforeLoadData","finishedLoadData","onWriteCountOnlick",],
  data() {
    return {
      columns: [
        { field: "trueName", key: "trueName", title: "姓名", width: "70px" },
        {
          field: "writeCount",
          key: "writeCount",
          title: "总结数",
          renderBodyCell: ({ row }) => {
            return (
              <span
                class="text-bold"
                style="color:#1890ff;"
                on-click={() => this.$emit("onWriteCountOnlick", row)}
              >
                {row.writeCount}
              </span>
            );
          },
        },
        { field: "readCount", key: "readCount", title: "浏览数" },
        { field: "readUserCount", key: "readUserCount", title: "浏览人数" },
        { field: "commentCount", key: "commentCount", title: "评论数" },
        { field: "likeCount", key: "likeCount", title: "点赞数" },
        { field: "sharedCount", key: "sharedCount", title: "分享数" },
      ],
      tableData: [],
    };
  },
  created() {
    this.onload();
  },
  methods: {
    onload() {
      if (this.row && this.row.name && this.tableData.length <= 0) {
        this.$emit("beforeLoadData")
        getUserStatisticalData({
          departmentName: this.row.name,
          timeRange: this.timeRange,
        }).then((d) => {
          this.$emit("finishedLoadData")
          if (d.data.success) {
            this.tableData = d.data.data;
          } else if (d.data.errorMessage) {
            alert(d.data.errorMessage);
          }
        });
      }
    },
  },
};
</script>
<style>
.child-vetable {
}
</style>
import request from '@/utils/request'

export function addTop (params) {
    return request({
      url: '/api/Top',
      data: { ...params },
      method: 'post',
      header:{
        'Content-Type':'application/json;charset=utf-8'  //如果写成contentType会报错
      },
      
    })
  }